
    import Vue from "vue";

    const END_POINT = "/delivery-schedule";

    const getDeliveryScheduleById = id => Vue.axios.get(END_POINT + "/" + id);

    const getDeliveryScheduleByDates = (productIds, start, end) => Vue.axios.get(END_POINT + "/by-dates", {params: {productIds, start, end} });

    const createDeliverySchedule = schedule => Vue.axios.post(END_POINT,  schedule );

    const updateDeliverySchedule = schedule => Vue.axios.put(END_POINT  + "/" + schedule.id,  schedule );

    const deleteDeliveryScheduleById = id => Vue.axios.delete(END_POINT + "/" + id);


    export {
      getDeliveryScheduleById,
      deleteDeliveryScheduleById,
      getDeliveryScheduleByDates,
      createDeliverySchedule,
      updateDeliverySchedule
    };
    